import React from "react";
import * as RetrieveDocumentBP from "../../../blueprints/stuff/retrieve-document";
import * as SaveDocumentBP from "../../../blueprints/stuff/save-document";
import * as DocsEditingSpec from "../../../blueprints/docs/docs-editing-pubsub-spec";
import { seamlessClient } from "../../../seamless-client";
import { usePath } from "@hiyllo/omni-router";
import { type StuffEDataParamsType } from "../../../types/navigation/edata";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { useDebounce } from "@hiyllo/ux/use-debounce";
import { Tenant } from "../../../platform/tenancy";
import { useSelf } from "@hiyllo/omni-continuity/main";
import "@hiyllo/editor/src/editor.css";
import { TabDetails } from "../../tokyo/tabbing/tabs-provider";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { Editor, EditorRef } from "./v2/editor-v2";
import { BaseOperation, Selection } from "slate";
import { getHTMLPreview } from "./v2/serialize-html";
import { DocumentContentsV2 } from "../../../types/stuff/document";
import { UseMoopsyQueryRetVal } from "@moopsyjs/react/main";
import { useUploadFile } from "@hiyllo/omni-user-files/main";

export const DocumentViewV2 = React.memo(function DocumentViewV2(props: {
  retrieveDocumentQuery: UseMoopsyQueryRetVal<RetrieveDocumentBP.Plug>;
  contents: DocumentContentsV2;
}): JSX.Element {
  const { uuid } = usePath().params as StuffEDataParamsType;

  if (uuid == null) {
    throw new Error("uuid is null");
  }

  const retrieveDocumentQuery =
    seamlessClient.useQuery<RetrieveDocumentBP.Plug>(
      RetrieveDocumentBP,
      {
        uuid,
      },
    );
  const saveDocumentMutation =
    seamlessClient.useMutation<SaveDocumentBP.Plug>(SaveDocumentBP);

  const saveDebounce = useDebounce(750, "overwrite");
  const contentsRef = React.useRef<DocumentContentsV2 | null>(null);
  const [unsavedChanges, setUnsavedChanges] = React.useState<boolean>(false);
  const self = useSelf();
  const editorId = React.useRef(Math.random().toString(36).substring(7));
  const editorRef = React.useRef<EditorRef>(null);
  const applyingRemoteChangesRef = React.useRef<boolean>(false);

  const onDocsEditMessage = React.useCallback(
    (data: DocsEditingSpec.Typings["MessageType"]) => {
      console.log('>>> subscribing', data.delta);
      const editor = editorRef.current?.editor;
      if (data.delta != null) {
        editor?.withoutNormalizing(() => {
          applyingRemoteChangesRef.current = true;
          for (const op of data.delta) {
            if (op.editorId !== editorId.current) {
              editor?.apply(op);
            }
          }
          applyingRemoteChangesRef.current = false;
        });
      }
      // TODO: Cursor "#1976d2"
    },
    [],
  );

  const psTopic = React.useRef(`[${Tenant}]docs-editing/${uuid}`).current;
  const DocsEditingPubsub = seamlessClient.usePublishToTopic(DocsEditingSpec);
  const uploadImage = useUploadFile();
  seamlessClient.useSubscribeToTopic(
    DocsEditingSpec,
    psTopic,
    onDocsEditMessage,
  );

  const onSelectionChanged = React.useCallback((selection: Selection) => {
    console.log('>>> publishing selection');

    if (!applyingRemoteChangesRef.current) {
      DocsEditingPubsub.publish(psTopic, {
        editorId: editorId.current,
        userId: self.profile?.name ?? self.userId,
        delta: null,
        range: selection,
      });
    }
  }, [DocsEditingPubsub, psTopic, self.profile?.name, self.userId]);

  const onContentsChanged = React.useCallback(
    (contents: DocumentContentsV2, operations: BaseOperation[]) => {


      setUnsavedChanges(true);
      contentsRef.current = contents;

      if (!applyingRemoteChangesRef.current) {
        console.log('>>> publishing operations', operations);
        DocsEditingPubsub.publish(psTopic, {
          editorId: editorId.current,
          userId: self.profile?.name ?? self.userId,
          // @ts-expect-error ---
          delta: operations.filter(o => o.editorId == null).map(o => {
            // @ts-expect-error ---
            o.editorId = editorId.current;
            return o;
          }),
          range: editorRef.current?.editor?.selection
        });
      }

      saveDebounce.debounce(() => {
        if (contentsRef.current != null) {
          const contents = contentsRef.current;

          console.log('>>> saving', {
            uuid,
            contents,
            contentPreview:
              getHTMLPreview(contents) ??
              "<div></div>",
          });

          void saveDocumentMutation
            .call({
              uuid,
              contents,
              contentPreview:
                getHTMLPreview(contents) ??
                "<div></div>",
            })
            .then(() => {
              setUnsavedChanges(contents !== contentsRef.current);
            });
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveDebounce, saveDocumentMutation, uuid],
  );

  if (retrieveDocumentQuery.data == null) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <TabDetails label={retrieveDocumentQuery.data.document.name} icon={faFile}>
      <Editor
        ref={editorRef}
        onValueChanged={onContentsChanged}
        onSelectionChanged={onSelectionChanged}
        isSaving={saveDocumentMutation.isLoading || unsavedChanges}
        initialContents={retrieveDocumentQuery.data.document.contents.v2 === true ? retrieveDocumentQuery.data.document.contents : null}
        onImageUploaded={blob => {
          return uploadImage(new File([blob], "image"));
        }}
      />
    </TabDetails>
  );
});
