import { styled } from "@hiyllo/ux/styled";
import { Select } from "@hiyllo/ux/select";
import { withHistory } from "slate-history";
import React, { useImperativeHandle } from "react";
import { ColorPicker } from "@hiyllo/ux/color-picker";
import { TypedEventEmitterV3 } from "@moopsyjs/toolkit";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Editable, ReactEditor, RenderLeafProps, Slate, withReact } from "slate-react";
import { faBold, faItalic, faKeyboard, faList, faPalette, faQuestionCircle, faTextSize, faUnderline } from "@fortawesome/pro-light-svg-icons";
import { BaseOperation, BaseText, createEditor, Descendant, Selection, Editor as SlateEditor, Transforms } from "slate";
import { motion } from "framer-motion";

import { Formats } from "./formats";
import { Element, withEmbeds } from "./elements";
import { toggleBlock } from "./marks/block-marks";
import { useOnPaste } from "./hooks/use-on-paste";
import { useOnKeyDown } from "./behaviors/hotkeys";
import { useEditorMarks } from "./marks/use-editor-marks";
import { withImageDragIn } from "./behaviors/image-drag-in";
import { DescendantType, DocumentContentsV2 } from "./types";
import { withCustomEnterBehavior } from "./behaviors/custom-enter";
import { withVoidNodeCursorFix } from "./behaviors/void-node-cursor-fix";
import { BooleanMarkEnum, toggleBooleanMark } from "./marks/boolean-marks";
import { withDeleteFirstLineBehavior } from "./behaviors/delete-first-line";
import { EditorIsReadOnly, EditorMetaKeyHeld } from "./contexts";
import { Modal } from "@hiyllo/ux/modal";
import { Typography } from "@hiyllo/ux/typography";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { Caret } from "./components/caret";

export const EditorCtx = React.createContext<ReactEditor | null>(null);

interface HiylloText extends BaseText {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  fontSize?: string | number;
  color?: string;
}

const Leaf = React.memo(function Leaf(props: RenderLeafProps): JSX.Element {
  const { attributes, children, leaf } = props;
  const { text, ...rest } = (leaf as HiylloText);

  const styles = React.useMemo(() => {
    const styles: React.CSSProperties = {};

    if (rest.bold) {
      styles.fontWeight = "bold";
    }

    if (rest.italic) {
      styles.fontStyle = "italic";
    }

    if (rest.underline) {
      styles.textDecoration = "underline";
    }

    if (rest.fontSize) {
      styles.fontSize = rest.fontSize;
      styles.lineHeight = `${Number(rest.fontSize) + 2.5}px`;
    }

    if (rest.color) {
      styles.color = rest.color;
    }

    return styles;
  }, [rest]);

  return (
    <span {...attributes} {...rest} style={styles} className={Object.keys(rest).join(" ")}>
      {children}
    </span>
  );
});

const initialValue: Descendant[] = [
  {
    // @ts-expect-error ---
    type: "paragraph",
    children: [{ text: "I'm a new document..." }],
  },
];

const EditorContainer = styled<"div", { noPadding?: boolean }>(
  "div",
  ({ $theme, noPadding }) => ({
    background: $theme.background1,
    height: noPadding ? "100%" : "calc(100% - 25px)",
    width: noPadding ? "100%" : "calc(100% - 25px)",
    display: "flex",
    flexDirection: "column",
    padding: noPadding ? 0 : 12.5,
  }),
);

const EditorDocumentName = styled("div", ({
  fontSize: 24,
  fontWeight: "bold",
  fontFamily: "hiyllo",
}));

const EditorMain = styled("div", ({
  display: "flex",
  flexDirection: "column",
  gap: 5,
  width: "100%",
  height: "100%",
  // flexGrow: 1,
}));

const EditorSidebar = styled("div", ({
  // width: 170,
  paddingTop: 0,
  paddingBottom: 5,
  display: "flex",
  flexDirection: "row",
  gap: 17.5,
  //
}));

const EditorContentArea = styled("div", ({ $theme }) => ({
  background: $theme.background2,
  borderRadius: 5,
  height: "calc(100% - 32px)",
  width: "calc(100% - 32px)",
  padding: 16,
  display: "flex",
  flexDirection: "column",
  whiteSpace: "pre-wrap",
  overflowY: "auto"
}));

const EditorContentContainer = styled("div", ({
  width: "100%",
  height: "100%",
}));

const EditorSidebarSimpleButtonContainer = styled<"div", { active: boolean }>(
  "div",
  ({ $theme, active }) => ({
    background: active ? $theme.midground1 : $theme.background3,
    height: 22.5,
    paddingLeft: 5,
    paddingRight: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    fontSize: 16,
    cursor: "pointer",
  }),
);

const ButtonGroup = styled("div", ({ $theme }) => ({
  borderRadius: 5,
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  gap: 1
}));

const SavingIndicatorRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 16,
});

const KeyboardHint = styled("div", ({ $theme }) => ({
  fontSize: 10,
  paddingTop: 2.5,
  paddingBottom: 2.5,
  paddingLeft: 2.5,
  paddingRight: 3.5,
  borderRadius: 5,
  gap: 2.5,
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const KeyboardHintInner = styled("div", ({ $theme }) => ({
  height: "1em",
  minWidth: "0.5em",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

type KeyCombination = string[];

const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
const keyMap = {
  meta: isMac ? "⌘" : "Ctrl",
  alt: isMac ? "⌥" : "Alt",
} as const;

export function joinWith<T>(arr: T[], separator: T): T[] {
  return arr.reduce((acc, cur, i) => {
    if (i === 0) return [cur];
    return [...acc, separator, cur];
  }, [] as T[]);
}

export const KeyCombinationHint = React.memo(function KeyCombinationHint(props: {
  combination: KeyCombination;
  style?: React.CSSProperties;
}): JSX.Element {
  return (
    <KeyboardHint style={props.style}>
      {joinWith(props.combination.map(c => c in keyMap ? keyMap[c as keyof typeof keyMap] : c).map(v => (<KeyboardHintInner key={v}>{v}</KeyboardHintInner>)), <KeyboardHintInner>+</KeyboardHintInner>)}
    </KeyboardHint>
  );
});

const EditorSidebarSimpleButton = React.memo(
  function EditorSidebarSimpleButton(props: {
    active?: boolean;
    icon?: IconDefinition;
    label?: JSX.Element | string;
    onClick?: (evt: React.MouseEvent) => void;
    keyShortcut?: KeyCombination;
  }): JSX.Element {
    const metaKeyHeld = React.useContext(EditorMetaKeyHeld);
    const onClick = React.useCallback(
      (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.onClick?.(e);
      },
      [props.onClick],
    );

    return (
      <EditorSidebarSimpleButtonContainer
        active={props.active ?? false}
        onMouseDown={onClick}
      >
        <KeyboardHintInner style={{ minWidth: "0.8em", fontSize: 12.5 }}>
          {props.icon != null ? <FontAwesomeIcon icon={props.icon} color="white" style={{ fontSize: 12.5 }} /> : null}
          {props.label}
        </KeyboardHintInner>
        {props.keyShortcut != null ?
          <motion.div animate={{ width: metaKeyHeld ? "" : 0 }} style={{ overflow: "hidden" }} initial={{ width: 0 }}>
            <KeyCombinationHint combination={props.keyShortcut} style={{ marginLeft: 5 }} />
          </motion.div>
          : null}
      </EditorSidebarSimpleButtonContainer>
    );
  },
);

const EditorSidebarSimpleButtonsContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 5,
  alignItems: "center",
  flexGrow: 1
}));

export interface EditorRef {
  editor: ReactEditor | null;
}

export interface EditorPropsType {
  initialContents?: DocumentContentsV2 | null;
  initialHTML?: string | null;
  onValueChanged: (contents: DocumentContentsV2, delta: BaseOperation[]) => void;
  isSaving?: boolean;
  name?: string | null;
  noPadding?: boolean;
  contentPrefix?: React.ReactNode | JSX.Element;
  contentSuffix?: React.ReactNode | JSX.Element;
  readOnly?: boolean;
  extraElementBelowName?: React.ReactNode | JSX.Element;
  onExportBlob?: null | ((blob: Blob) => void);
  onImageUploaded?: (image: Blob) => Promise<{ src: string, fsId: string }>;
  onSelectionChanged?: (selection: Selection) => void;
}

export type EditorEmitter = TypedEventEmitterV3<{ change: null }>;

const EditorFR = React.forwardRef<EditorRef, EditorPropsType>(
  function Editor(props, forwardedRef): JSX.Element {
    const [showHints, setShowHints] = React.useState(false);
    const blurredSelectionRef = React.useRef<Selection | null>(null);
    const editorRef = React.useRef<ReactEditor | null>(null);
    const emitter = React.useMemo(() => new TypedEventEmitterV3<{ change: null }>(), []);
    const editor: ReactEditor = React.useMemo(() => withEmbeds(
      withImageDragIn(
        withDeleteFirstLineBehavior(
          withVoidNodeCursorFix(
            withCustomEnterBehavior(
              withHistory(
                withReact(
                  createEditor()
                )
              )
            )
          )
        ),
        async file => {
          if (props.onImageUploaded == null) return {
            fsId: "",
            src: URL.createObjectURL(file),
          };

          return props.onImageUploaded(file);
        }
      )
    ) as ReactEditor, []);
    editorRef.current = editor;

    const [metaKeyHeld, setMetaKeyHeld] = React.useState(false);
    const [altKeyHeld, setAltKeyHeld] = React.useState(false);
    const [commandPaletteOpen, setCommandPaletteOpen] = React.useState(false);
    const onKeyDown = useOnKeyDown(editor, { setMetaKeyHeld, setAltKeyHeld, setCommandPaletteOpen });

    React.useEffect(() => {
      const fn = () => {
        setMetaKeyHeld(false);
        setAltKeyHeld(false);
      };

      window.addEventListener("mousedown", fn);

      return () => {
        window.removeEventListener("mousedown", fn);
      };
    }, []);

    const onChange = React.useCallback((newValue: Descendant[]) => {
      emitter.emit("change", null);
      props.onValueChanged?.({ v2: true, descendants: newValue as DescendantType[] }, editorRef.current?.operations ?? []);
    }, [emitter, props]);

    useImperativeHandle(
      forwardedRef,
      () => ({
        editor,
      }),
      [editor],
    );
    const marks = useEditorMarks(editor, emitter);

    // @ts-expect-error ---
    const isBold = marks?.bold === true;

    // @ts-expect-error ---
    const isItalic = marks?.italic === true;

    // @ts-expect-error ---
    const isUnderline = marks?.underline === true;

    // @ts-expect-error ---
    const fontSize: number = marks?.fontSize ?? 15;

    const bold = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      toggleBooleanMark(editorRef.current, BooleanMarkEnum.bold);
    }, []);

    const italic = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      toggleBooleanMark(editorRef.current, BooleanMarkEnum.italic);
    }, []);

    const underline = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      toggleBooleanMark(editorRef.current, BooleanMarkEnum.underline);
    }, []);

    const bulletList = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      toggleBlock(editorRef.current, "bulleted-list");
    }, []);

    const collapse = React.useCallback((evt: React.MouseEvent) => {
      evt.preventDefault();
      if (editorRef.current == null) return;
      toggleBlock(editorRef.current, "collapse", [{ type: "paragraph", children: [{ text: "" }] }]);
    }, []);

    const onPaste = useOnPaste(editor, props.onImageUploaded);

    const onBlur = React.useCallback(() => {
      if (editorRef.current == null) return;
      blurredSelectionRef.current = editorRef.current.selection ?? null;
    }, []);

    const onSelectColor = React.useCallback((color: string) => {
      SlateEditor.addMark(editor, "color", color);
      ReactEditor.focus(editor);
    }, [editor]);

    const [showFormats, setShowFormats] = React.useState(false);

    const value = (props.initialContents?.descendants as Descendant[] | void);

    if (props.readOnly) {
      return (
        <EditorIsReadOnly.Provider value={true}>
          <EditorCtx.Provider value={editor}>
            <Slate editor={editor} initialValue={value && value.length > 0 ? value : initialValue}>
              <Editable
                renderElement={props => <Element {...props} />}
                renderLeaf={props => <Leaf {...props} />}
                disableDefaultStyles
                readOnly
                style={{ border: 'none', outline: 'none', height: '100%', width: '100%' }}
              />
            </Slate>
          </EditorCtx.Provider>
        </EditorIsReadOnly.Provider>
      );
    }

    return (
      <EditorMetaKeyHeld.Provider value={metaKeyHeld}>
        <EditorCtx.Provider value={editor}>
          {showHints ?
            <Modal onClose={() => setShowHints(false)}>
              <div style={{ color: "white" }}>
                <Typography.Header>Hiyllo Editor Keyboard Shortcuts</Typography.Header>
                <div style={{ height: 10 }} />
                <Typography.SubHeader>Formatting</Typography.SubHeader>
                <Typography.HeaderRow>
                  <KeyCombinationHint combination={["meta", "B"]} />
                  <div>
                    Bold
                  </div>
                </Typography.HeaderRow>
                <Typography.HeaderRow>
                  <KeyCombinationHint combination={["meta", "I"]} />
                  <div>
                    Italic
                  </div>
                </Typography.HeaderRow>
                <Typography.HeaderRow>
                  <KeyCombinationHint combination={["meta", "U"]} />
                  <div>
                    Underline
                  </div>
                </Typography.HeaderRow>
                <div style={{ height: 10 }} />
                <Typography.SubHeader>Selection</Typography.SubHeader>
                <Typography.HeaderRow>
                  <KeyCombinationHint combination={["alt", "Shift", "W"]} />
                  <div>
                    Select Nearest Word
                  </div>
                </Typography.HeaderRow>
                <Typography.HeaderRow>
                  <KeyCombinationHint combination={["alt", "Shift", "L"]} />
                  <div>
                    Select Entire Line
                  </div>
                </Typography.HeaderRow>
              </div>
            </Modal>
            : null}
          <EditorContainer noPadding={props.noPadding}>
            <EditorMain>
              <EditorSidebar>
                {props.name != null ? (
                  <EditorDocumentName>{props.name}</EditorDocumentName>
                ) : null}
                {props.extraElementBelowName}
                <EditorSidebarSimpleButtonsContainer
                  onMouseLeave={() => {
                    setMetaKeyHeld(false);
                    setShowFormats(false);
                  }}
                >
                  <ButtonGroup>
                    <EditorSidebarSimpleButton
                      icon={faBold}
                      onClick={bold}
                      active={isBold}
                      keyShortcut={["meta", "B"]}
                    />
                    <EditorSidebarSimpleButton
                      icon={faItalic}
                      onClick={italic}
                      active={isItalic}
                      keyShortcut={["meta", "I"]}
                    />
                    <EditorSidebarSimpleButton
                      icon={faUnderline}
                      onClick={underline}
                      active={isUnderline}
                      keyShortcut={["meta", "U"]}
                    />
                  </ButtonGroup>
                  <ButtonGroup>
                    <EditorSidebarSimpleButton
                      icon={faList}
                      onClick={bulletList}
                      active={false}
                    />
                  </ButtonGroup>
                  {/* <EditorSidebarSimpleButton
                  icon={faArrowsMinimize}
                  onClick={collapse}
                  active={false}
                /> */}
                  <ButtonGroup>
                    <ColorPicker onSelectColor={onSelectColor}>
                      <EditorSidebarSimpleButton icon={faPalette} active={false} />
                    </ColorPicker>
                  </ButtonGroup>

                  <ButtonGroup>
                    <EditorSidebarSimpleButton
                      icon={faTextSize}
                      onClick={() => setShowFormats(v => !v)}
                      active={isUnderline}
                    />
                    <motion.div
                      style={{ overflow: 'hidden', display: "flex", flexDirection: "row", gap: 1 }}
                      initial={{ width: 0 }}
                      animate={{ width: showFormats ? 'auto' : 0 }}
                    >
                      {Formats.map(format => (
                        <EditorSidebarSimpleButton
                          label={format.label}
                          onClick={() => {
                            ReactEditor.focus(editor);
                            if (blurredSelectionRef.current != null) {
                              Transforms.select(editor, blurredSelectionRef.current);
                            }
                            SlateEditor.addMark(editor, "fontSize", format.value);
                          }}
                          key={format.value}
                        />
                      ))}
                    </motion.div>
                  </ButtonGroup>

                  {/* <ButtonGroup>
                    <EditorSidebarSimpleButton
                      icon={faKeyboard}
                      onClick={(evt) => {
                        setShowHints(true);
                        evt.stopPropagation();
                      }}
                      active={false}
                    />
                  </ButtonGroup> */}
                  <CircleButton
                    icon={faQuestionCircle}
                    secondary
                    size={25}
                    onClick={() => setShowHints(true)}
                  />
                </EditorSidebarSimpleButtonsContainer>

                {props.isSaving ? (
                  <SavingIndicatorRow>
                    <LoadingSpinner />
                    Saving...
                  </SavingIndicatorRow>
                ) : null}
              </EditorSidebar>
              <EditorContentContainer>
                <EditorContentArea>
                  {props.contentPrefix}
                  <Slate editor={editor} initialValue={value && value.length > 0 ? value : initialValue} onChange={onChange} onSelectionChange={props.onSelectionChanged}>
                    <Editable
                      renderElement={props => <Element {...props} />}
                      renderLeaf={props => <Leaf {...props} />}
                      // placeholder="Enter some text..."
                      disableDefaultStyles
                      onPaste={onPaste}
                      onBlur={onBlur}
                      onKeyDown={onKeyDown}
                      style={{ border: 'none', outline: 'none', height: '100%', width: '100%' }}
                    />
                    <Caret
                      altKeyHeld={altKeyHeld}
                      editor={editor}
                      commandPaletteOpen={commandPaletteOpen}
                      onCloseCommandPalette={() => setCommandPaletteOpen(false)}
                    />
                  </Slate>
                  {props.contentSuffix}
                </EditorContentArea>
              </EditorContentContainer>
            </EditorMain>
          </EditorContainer>
        </EditorCtx.Provider>
      </EditorMetaKeyHeld.Provider>
    );
  },
);

export const Editor = React.memo(EditorFR) as typeof EditorFR;