import { styled, styledRN } from "@hiyllo/ux/styled";
import { View } from "react-native";

export const BoardColumnsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 15,
  height: "100%",
});

export const BoardColumnContainer = styled("div", {
  flexBasis: 0,
  height: "100%",
  flexShrink: 1,
  flexGrow: 1,
});

export const BoardColumn = styled<"div", { frameless?: boolean }>(
  "div",
  ({ $theme, frameless }) => ({
    height: frameless ? "100%" : "calc(100% - 10px)",
    backgroundColor: frameless ? "transparent" : $theme.background2,
    padding: frameless ? 0 : 5,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
  }),
);

export const CardsContainer = styled("div", {
  height: 0,
  flexGrow: 1,
  overflowY: "auto",
  overflowX: "visible"
});

export const ColumnHeader = styled("div", {
  fontSize: 24,
  marginBottom: 10,
  padding: 5,
  paddingBottom: 0,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

export const ColumnHeaderTitleText = styled("div", { userSelect: "none" });

export const SubtleButton = styled("div", ({ $theme }) => ({
  width: 25,
  height: 25,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  fontSize: 14,
  background: $theme.midground,
  borderRadius: 5,
  cursor: "pointer",
}));

export const ProjectName = styled("div", {
  fontSize: 10,
  opacity: 0.8,
  lineHeight: "1em",
});
