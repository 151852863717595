import React from "react";
import { useParams } from "react-router-dom";
import { EventMeetingView } from "./meeting-view";
import { Input } from "@hiyllo/ux/input";
import { styled } from "@hiyllo/ux/styled";
import { CircleButton } from "@hiyllo/ux/circle-button";
import {
  ContinuityMeetingContext,
  MeetingProvider,
  useOnLeaveMeeting,
} from "../../../main/meeting-provider";
import { RouterProviderV1 } from "../../tokyo/navigation/routers/router-v1";
import * as JoinMeetingBP from "../../../blueprints/meet/join-meeting-with-spectator-token";
import { seamlessClient } from "../../../seamless-client";
import { VideoMeetingUI } from "./video-meeting-ui";
import { LoadingSpinnerView } from "@hiyllo/ux/loading-spinner";

const ExternalBackgroundView = styled("div", ({ $theme }) => ({
  background: $theme.background1,
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const Header = styled("div", ({ $theme }) => ({
  color: $theme.foreground,
  fontSize: 17.5,
  marginBottom: 10,
}));

const SpectatorExternalViewInternal = React.memo(
  function SpectatorExternalViewInternal({
    started,
    setStarted
  }: {
    started: Date;
    setStarted: React.Dispatch<React.SetStateAction<Date>>;
  }): JSX.Element {
    const params = useParams<{
      token: string;
      meetingPassword: string;
    }>();
    const joinMeetingMutation = seamlessClient.useMutation<JoinMeetingBP.Plug>(JoinMeetingBP);
    const { connect, current } = React.useContext(ContinuityMeetingContext);
    const token = params.token as string;
    const nameRef = React.useRef<HTMLInputElement>(null);
    const [left, setLeft] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string | null>(null);


    useOnLeaveMeeting(() => setLeft(true));

    const saveName = React.useCallback(async () => {
      if (nameRef.current == null) {
        return;
      }

      const res = await joinMeetingMutation.call({
        token,
        name: nameRef.current.value,
      });

      setStarted(res.started);

      connect({
        token: res.jwt,
        serverUrl: res.meetingServerURL,
        room: res.room,
        label: res.label,
        started: res.started,
        mip: {
          videoEnabled: false,
          audioEnabled: false,
          videoDeviceId: ""
        },
        videoMeeting: null,
        isRecording: false
      });

      setName(nameRef.current.value);
    }, [connect, joinMeetingMutation, setStarted, token]);

    if (left) {
      return (
        <ExternalBackgroundView>
          <Header>You have left the meeting</Header>
          <div style={{ height: 20 }} />
          <img src="/ufplogo.png" style={{ height: 50, width: "auto" }} />
        </ExternalBackgroundView>
      );
    }

    if (name === null && current == null) {
      return (
        <ExternalBackgroundView>
          <Header>Enter your name to watch</Header>
          <Input ref={nameRef} onSubmit={saveName} />
          <div style={{ height: 10 }} />
          <CircleButton awaitOnClickForLoading onClick={saveName} size={50} />
        </ExternalBackgroundView>
      );
    }

    if (current == null) {
      return (
        <LoadingSpinnerView />
      );
    }

    return (
      <VideoMeetingUI spectator started={started} />
    );
  },
);

export const SpectatorExternal = React.memo(function SpectatorExternal(): JSX.Element {
  const [started, setStarted] = React.useState<Date>(new Date(0));

  return (
    <RouterProviderV1>
      <MeetingProvider>
        <SpectatorExternalViewInternal
          started={started}
          setStarted={setStarted}
        />
      </MeetingProvider>
    </RouterProviderV1>
  );
});
